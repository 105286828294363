import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const YouTube = makeShortcode("YouTube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`On September 14, 2019, Stripe introduced a API for handling payments to comply with new European legislation. If you are already using Stripe, you've probably already heard of or even implemented `}<strong parentName="p">{`Payment Intents`}</strong>{`.`}</p>
    <p>{`Companies who don't follow the new changes may see transactions being declined by the bank, leaving customers in the dark and reduce sales. 📉`}</p>
    <YouTube youTubeId="szRNm8mDrNY" mdxType="YouTube" />
    <p>{`If your business is based in the European Economic Area or process payments for anybody who is, serve customers in the EEA and accept credit or debit cards, you will need to comply with the `}<a parentName="p" {...{
        "href": "https://stripe.com/docs/strong-customer-authentication"
      }}>{`Strong Customer Authentication`}</a>{`.`}</p>
    <p>{`Due to the change in processing payments, it's not longer possible to just create payments on the frontend like you were used to previously. There now has to be a server element to creating payments with Stripe.`}</p>
    <p>{`In this guide, we'll explore how you can start using the new Stripe Payment Intents API with Next.js and follow best practices as set out by Stripe and the industry.`}</p>
    <p>{`TLDR; `}<a parentName="p" {...{
        "href": "https://github.com/notrab/nextjs-stripe-intents"
      }}>{`Get the code`}</a></p>
    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "https://dashboard.stripe.com"
        }}>{`Stripe`}</a>{` account`}</li>
      <li parentName="ul">{`Stripe `}<inlineCode parentName="li">{`Secret key`}</inlineCode>{` and `}<inlineCode parentName="li">{`Publishable key`}</inlineCode></li>
      <li parentName="ul">{`Some knowledge of React & Next.js`}</li>
    </ul>
    <h2>{`Get started`}</h2>
    <p>{`Let's use the Next.js CLI to create a new project and boilerplate.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm init next-app # or yarn create next-app
`}</code></pre>
    <p>{`Give your project a name and once the dependencies are installed, `}<inlineCode parentName="p">{`cd`}</inlineCode>{` into your project folder.`}</p>
    <p>{`Now, create the file: `}<inlineCode parentName="p">{`pages/checkout.js`}</inlineCode>{` and add the following;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const CheckoutPage = (props) => <pre>{JSON.stringify(props, null, 2)}</pre>;

export default CheckoutPage;
`}</code></pre>
    <p>{`If you now run `}<inlineCode parentName="p">{`npm run dev`}</inlineCode>{` (or `}<inlineCode parentName="p">{`yarn dev`}</inlineCode>{`) you'll see we have an empty `}<inlineCode parentName="p">{`props`}</inlineCode>{` object rendered on the page.`}</p>
    <p>{`⚠️ `}<em parentName="p">{`Stripe recommends we create a Payment Intent as soon as the amount is known and the customer begins the checkout flow. Payment Intents can also be retrieved if a user is returning to your site at a later date to complete a checkout for the same cart or order they were previously.`}</em></p>
    <p>{`Now let's move on... and do just that! 👯‍♀️`}</p>
    <h2>{`Server side`}</h2>
    <p><a parentName="p" {...{
        "href": "https://nextjs.org"
      }}>{`Next.js`}</a>{` 9.3.0 `}<a parentName="p" {...{
        "href": "https://nextjs.org/blog/next-9-3#getserversideprops"
      }}>{`introduced`}</a>{` a the new `}<inlineCode parentName="p">{`getServerSideProps`}</inlineCode>{` lifecycle method we can use to perform server side only behaviour.`}</p>
    <p>{`This means we no longer need to create an API route to handle creating an intent and handle it directly inside our checkout page.`}</p>
    <p>{`Let's start by installing the Stripe.js dependency;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install stripe # or yarn add stripe
`}</code></pre>
    <p>{`Then inside our `}<inlineCode parentName="p">{`pages/checkout.js`}</inlineCode>{` page, import the Stripe package at the top of our file;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import Stripe from "stripe";
`}</code></pre>
    <p>{`Now to hook into the `}<inlineCode parentName="p">{`getServerSideProps`}</inlineCode>{` method, we must export it as a `}<inlineCode parentName="p">{`const`}</inlineCode>{`.`}</p>
    <p>{`Inside that method is where we will create our Payment Intent. For the purposes of this tutorial, we'll fix the `}<inlineCode parentName="p">{`amount`}</inlineCode>{` and `}<inlineCode parentName="p">{`currency`}</inlineCode>{` values but inside this method is where I'd recommend making a `}<inlineCode parentName="p">{`fetch`}</inlineCode>{` request to lookup your cart total. Unless your use case permits users to provide their own `}<inlineCode parentName="p">{`amount`}</inlineCode>{` and `}<inlineCode parentName="p">{`currency`}</inlineCode>{` 😀.`}</p>
    <p>{`⚠️ `}<strong parentName="p">{`Make sure you head to your `}<a parentName="strong" {...{
          "href": "https://dashboard.stripe.com/test/apikeys"
        }}>{`Developer API Keys Dashboard`}</a>{` and copy your `}<em parentName="strong">{`Secret key`}</em>{` for the next bit`}</strong>{` .`}</p>
    <h3>{`Create a Payment Intent`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export const getServerSideProps = async () => {
  const stripe = new Stripe("STRIPE_SECRET_KEY_HERE");

  const paymentIntent = await stripe.paymentIntents.create({
    amount: 1000,
    currency: "gbp",
  });

  return {
    props: {
      paymentIntent,
    },
  };
};
`}</code></pre>
    <p>{`👀 If you know more about the customer, for example they are currently logged in, and know their `}<inlineCode parentName="p">{`email`}</inlineCode>{` or `}<inlineCode parentName="p">{`shipping`}</inlineCode>{` address, you can pass this onto the `}<inlineCode parentName="p">{`create`}</inlineCode>{` function too. You can see a `}<a parentName="p" {...{
        "href": "https://stripe.com/docs/api/payment_intents/create"
      }}>{`full list of arguments`}</a>{` over on the Stripe docs.`}</p>
    <p>{`Next, start the Next development server with `}<inlineCode parentName="p">{`npm run dev`}</inlineCode>{` (or `}<inlineCode parentName="p">{`yarn dev`}</inlineCode>{`) and head to `}<a parentName="p" {...{
        "href": "http://localhost:3000/checkout"
      }}><inlineCode parentName="a">{`http://localhost:3000/checkout`}</inlineCode></a>{`.`}</p>
    <p>{`🎉 Yay! You should see a Payment Intent object!`}</p>
    <hr></hr>
    <p>{`⚠️ `}<strong parentName="p">{`While this is great, every time you visit this page it will create a new Payment Intent, and as we touched on earlier, this isn't recommended.`}</strong></p>
    <h3>{`Retrieve existing Payment Intent`}</h3>
    <p><inlineCode parentName="p">{`getServerSideProps`}</inlineCode>{` would be a place to store some kind of cookie that can be checked, and if an existing ID exists, make a call to Stripe to retrieve the Payment Intent.`}</p>
    <p>{`Install `}<inlineCode parentName="p">{`nookies`}</inlineCode>{` to parse and set our cookies with Next.js context;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install nookies # yarn add nookies
`}</code></pre>
    <p>{`Then update `}<inlineCode parentName="p">{`pages/checkout.js`}</inlineCode>{` to import the `}<inlineCode parentName="p">{`nookies`}</inlineCode>{` dependency;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { parseCookies, setCookie } from "nookies";
`}</code></pre>
    <p>{`Now update `}<inlineCode parentName="p">{`getServerSideProps`}</inlineCode>{` to;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export const getServerSideProps = async (ctx) => {
  const stripe = new Stripe("STRIPE_SECRET_KEY_HERE");

  let paymentIntent;

  const { paymentIntentId } = await parseCookies(ctx);

  if (paymentIntentId) {
    paymentIntent = await stripe.paymentIntents.retrieve(paymentIntentId);

    return {
      props: {
        paymentIntent,
      },
    };
  }

  paymentIntent = await stripe.paymentIntents.create({
    amount: 1000,
    currency: "gbp",
  });

  setCookie(ctx, "paymentIntentId", paymentIntent.id);

  return {
    props: {
      paymentIntent,
    },
  };
};
`}</code></pre>
    <p>{`PS. Make sure not to override the Stripe Secret with `}<inlineCode parentName="p">{`STRIPE_SECRET_KEY_HERE`}</inlineCode>{` 🤪 if you're copy/pasting!`}</p>
    <p>{`Obviously the implementation may differ for your setup, but the idea of this tutorial is to teach the flow and best practices.`}</p>
    <p>{`Now if you head back to `}<a parentName="p" {...{
        "href": "http://localhost:3000/checkout"
      }}>{`http://localhost:3000/checkout`}</a>{` and refresh, you should see the same Payment Intent! 🎉`}</p>
    <hr></hr>
    <p>{`You can also see Payments created inside the `}{`[Stripe Dashboard]`}{`. Depending on how many times you loaded the `}<inlineCode parentName="p">{`/checkout`}</inlineCode>{` page, you should see 2 or more Payments. The most recent should be the one being reused and stored in a cookie.`}</p>
    <p><img parentName="p" {...{
        "src": "https://dev-to-uploads.s3.amazonaws.com/i/jylhoa1q8mo2fj2n8sxx.png",
        "alt": "Payments List"
      }}></img></p>
    <p>{`Stripe also shows all activity around payments, which includes the request parameters we sent to `}<inlineCode parentName="p">{`paymentIntents.create()`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://dev-to-uploads.s3.amazonaws.com/i/l555tj88aca4jdqd59eo.png",
        "alt": "Payment Activity"
      }}></img></p>
    <h2>{`Client side`}</h2>
    <p>{`Now it's time to capture the users card and process the payment. The Stripe Payment Intents API requires a `}<inlineCode parentName="p">{`paymentMethod`}</inlineCode>{` in order to process the transaction.`}</p>
    <p>{`We can use the client side Stripe libraries to create a secure `}<inlineCode parentName="p">{`paymentMethod`}</inlineCode>{` which contains our card information which is then passed onto Stripe.`}</p>
    <p>{`Install dependencies for the frontend:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @stripe/stripe-js @stripe/react-stripe-js # or yarn add @stripe/stripe-js @stripe/react-stripe-js
`}</code></pre>
    <h3>{`Configure Stripe on the frontend`}</h3>
    <p>{`Now with these installed, add the following import lines to the top of your `}<inlineCode parentName="p">{`pages/checkout.js`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
`}</code></pre>
    <p>{`We next need to create a Stripe `}<inlineCode parentName="p">{`Promise`}</inlineCode>{` to pass the `}<inlineCode parentName="p">{`Elements`}</inlineCode>{` provider. You'll need your `}<em parentName="p">{`Publishable key`}</em>{` from your `}<a parentName="p" {...{
        "href": "https://dashboard.stripe.com/test/apikeys"
      }}>{`Stripe Dashboard`}</a>{` and above your `}<inlineCode parentName="p">{`CheckoutPage`}</inlineCode>{` function, add the following;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const stripePromise = loadStripe("STRIPE_PUBLISHABLE_KEY");
`}</code></pre>
    <p>{`Let's finally update our `}<inlineCode parentName="p">{`CheckoutPage`}</inlineCode>{` function to wrap our page with `}<inlineCode parentName="p">{`Elements`}</inlineCode>{` and our newly created `}<inlineCode parentName="p">{`stripePromise`}</inlineCode>{` Promise.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const CheckoutPage = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <pre>{JSON.stringify(props, null, 2)}</pre>
    </Elements>
  );
};
`}</code></pre>
    <h3>{`Creating a Checkout Form`}</h3>
    <p>{`Go ahead and create the folder/file `}<inlineCode parentName="p">{`components/CheckoutForm.js`}</inlineCode>{` in the root of your project and add the following;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from "react";

const CheckoutForm = ({ paymentIntent }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  return <form onSubmit={handleSubmit}>{/* TODO */}</form>;
};

export default CheckoutForm;
`}</code></pre>
    <p>{`That's pretty much the basics configured for the `}<inlineCode parentName="p">{`CheckoutForm`}</inlineCode>{` which we next need to import and invoke on our `}<inlineCode parentName="p">{`pages/checkout.js`}</inlineCode>{` page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// pages/checkout.js

import CheckoutForm from "../components/CheckoutForm";
`}</code></pre>
    <p>{`Also update the `}<inlineCode parentName="p">{`CheckoutPage`}</inlineCode>{` function to pass `}<inlineCode parentName="p">{`paymentIntent`}</inlineCode>{` from `}<inlineCode parentName="p">{`props`}</inlineCode>{` to `}<inlineCode parentName="p">{`CheckoutForm`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// pages/checkout.js
const CheckoutPage = ({ paymentIntent }) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm paymentIntent={paymentIntent} />
  </Elements>
);
`}</code></pre>
    <p>{`⚠️ For whatever reason `}<inlineCode parentName="p">{`paymentIntent`}</inlineCode>{` does not exist, you might want to display a message to the user.`}</p>
    <h3>{`Working with the Stripe React hooks`}</h3>
    <p><inlineCode parentName="p">{`@stripe/react-stripe-js`}</inlineCode>{` is a new library by Stripe that exposes a few handy hooks and components for us to use. We'll be using;`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CardElement`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`useStripe`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`useElements`}</inlineCode></li>
    </ul>
    <p>{`Inside the `}<inlineCode parentName="p">{`CheckoutForm`}</inlineCode>{` function, we'll invoke both of the Stripe hooks so we have a reference to `}<inlineCode parentName="p">{`stripe`}</inlineCode>{` and `}<inlineCode parentName="p">{`elements`}</inlineCode>{` for use inside our `}<inlineCode parentName="p">{`handleSubmit`}</inlineCode>{` function next.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const CheckoutForm = ({ paymentIntent }) => {
  const stripe = useStripe();
  const elements = useElements();

  // ... rest of file
};
`}</code></pre>
    <h3>{`Confirm Stripe Intent with React hooks`}</h3>
    <p>{`A method on `}<inlineCode parentName="p">{`stripe`}</inlineCode>{` that we will need to use is `}<a parentName="p" {...{
        "href": "https://stripe.com/docs/js/payment_intents/confirm_card_payment"
      }}><inlineCode parentName="a">{`confirmCardPayment`}</inlineCode></a>{`, which accepts 3 arguments; `}<inlineCode parentName="p">{`client_secret`}</inlineCode>{`, and (`}<em parentName="p">{`optional: `}<inlineCode parentName="em">{`data`}</inlineCode>{` and `}<inlineCode parentName="em">{`options`}</inlineCode></em>{`).`}</p>
    <p>{`We already have the `}<inlineCode parentName="p">{`client_secret`}</inlineCode>{` passed down inside `}<inlineCode parentName="p">{`paymentIntent`}</inlineCode>{` through `}<inlineCode parentName="p">{`pages/index.js`}</inlineCode>{` `}<inlineCode parentName="p">{`getServerSideProps`}</inlineCode>{`, and then onto `}<inlineCode parentName="p">{`CheckoutForm`}</inlineCode>{` via a prop.`}</p>
    <p>{`Let's update the `}<inlineCode parentName="p">{`handleSubmit`}</inlineCode>{` function to send a request to Stripe to confirm the Payment Intent.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const handleSubmit = async (e) => {
  e.preventDefault(); // Stops the page from reloading!

  try {
    const {
      error,
      paymentIntent: { status },
    } = await stripe.confirmCardPayment(paymentIntent.client_secret);

    if (error) throw new Error(error.message);

    if (status === "succeeded") {
      alert("Payment made!");
    }
  } catch (err) {
    alert(err.message);
  }
};
`}</code></pre>
    <p>{`Since our Payment Intent was created on the server before the page loaded, and in this example we have no user context or stored payment method, we need to create one on the client and send it in the second argument to `}<inlineCode parentName="p">{`confirmCardPayment`}</inlineCode>{`.`}</p>
    <p>{`This is where the `}<inlineCode parentName="p">{`CardElement`}</inlineCode>{` component comes in. Stripe provides a fully secure and baked credit card input with expiry, CVV and zip/post code.`}</p>
    <p>{`Let's first add a `}<inlineCode parentName="p">{`<CardElement />`}</inlineCode>{` and `}<inlineCode parentName="p">{`<button />`}</inlineCode>{` component to submit the form. Let's also disable the `}<inlineCode parentName="p">{`button`}</inlineCode>{` if the `}<inlineCode parentName="p">{`stripe`}</inlineCode>{` Promise has not resolved yet.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`return (
  <form onSubmit={handleSubmit}>
    <CardElement />

    <button type="submit" disabled={!stripe}>
      Pay now
    </button>
  </form>
);
`}</code></pre>
    <p>{`Now if you refresh your checkout page at `}<a parentName="p" {...{
        "href": "http://localhost:3000/checkout"
      }}>{`http://localhost:3000/checkout`}</a>{` you should see something a little like this:`}</p>
    <p><img parentName="p" {...{
        "src": "https://dev-to-uploads.s3.amazonaws.com/i/0tjbn6ye956p93i0njh3.png",
        "alt": "Checkout Form with Card input"
      }}></img></p>
    <p>{`Now when we click `}<inlineCode parentName="p">{`Pay now`}</inlineCode>{` nothing will happen on the Stripe side because we haven't attached any payment method data to our Payment Intent.`}</p>
    <p>{`Now let's update `}<inlineCode parentName="p">{`handleSubmit`}</inlineCode>{` to do just that!`}</p>
    <p>{`We can use `}<inlineCode parentName="p">{`elements.getElement`}</inlineCode>{` and pass in our `}<inlineCode parentName="p">{`CardElement`}</inlineCode>{` import to reference the card input on our page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const {
  error,
  paymentIntent: { status },
} = await stripe.confirmCardPayment(paymentIntent.client_secret, {
  payment_method: {
    card: elements.getElement(CardElement),
  },
});
`}</code></pre>
    <p><strong parentName="p">{`Congratulations`}</strong>{`! We now have a fully functional payment form! 🎉🎉🎉`}</p>
    <p>{`Go give it a try with a test Stripe card. `}<inlineCode parentName="p">{`4242 4242 4242 4242`}</inlineCode>{` will get you through with no SCA challenge.`}</p>
    <p><img parentName="p" {...{
        "src": "https://dev-to-uploads.s3.amazonaws.com/i/wg24cjzpusdzrmgj03ob.gif",
        "alt": "Stripe successful payment"
      }}></img></p>
    <hr></hr>
    <p>{`Now we're not done just yet... You'll notice if you refresh the page and try to make a payment it will fail. `}<strong parentName="p">{`This is because we reusing the same `}<inlineCode parentName="strong">{`paymentIntentId`}</inlineCode>{` we stored in cookies which is now confirmed.`}</strong></p>
    <h2>{`Tidying it up`}</h2>
    <p>{`We've a few things to do;`}</p>
    <ul>
      <li parentName="ul">{`Destroy the `}<inlineCode parentName="li">{`paymentIntentId`}</inlineCode>{` cookie on successful payments`}</li>
      <li parentName="ul">{`Display a success message instead of a payment form`}</li>
      <li parentName="ul">{`Display an error is present`}</li>
    </ul>
    <h3>{`Destroying the `}<inlineCode parentName="h3">{`paymentIntentId`}</inlineCode>{` cookie on on successful payments`}</h3>
    <p>{`Inside `}<inlineCode parentName="p">{`components/CheckoutForm.js`}</inlineCode>{`, import `}<inlineCode parentName="p">{`destroyCookie`}</inlineCode>{` from `}<inlineCode parentName="p">{`nookes`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// ...
import { destroyCookie } from "nookies";
`}</code></pre>
    <p>{`Now inside our `}<inlineCode parentName="p">{`handleSubmit`}</inlineCode>{` function we check if the `}<inlineCode parentName="p">{`status`}</inlineCode>{` is `}<inlineCode parentName="p">{`succeeded`}</inlineCode>{`. It would be here we would need to call `}<inlineCode parentName="p">{`destroyCookie`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// ..
if (status === "succeeded") {
  destroyCookie(null, "paymentIntentId");
}
`}</code></pre>
    <h3>{`Implement success/error messages`}</h3>
    <p>{`Now let's import `}<inlineCode parentName="p">{`useState`}</inlineCode>{` from `}<inlineCode parentName="p">{`react`}</inlineCode>{` and invoke the hook for 2 different types of state;`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`checkoutError`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`checkoutSuccess`}</inlineCode></li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const CheckoutForm = ({ paymentIntent }) => {
// ...

const [checkoutError, setCheckoutError] = useState();
const [checkoutSuccess, setCheckoutSuccess] = useState();
`}</code></pre>
    <p>{`Now inside `}<inlineCode parentName="p">{`handleSubmit`}</inlineCode>{`, add `}<inlineCode parentName="p">{`setCheckoutSuccess`}</inlineCode>{` while passing `}<inlineCode parentName="p">{`true`}</inlineCode>{` on a successful payment and `}<inlineCode parentName="p">{`setCheckoutError(err.message)`}</inlineCode>{` inside the `}<inlineCode parentName="p">{`catch`}</inlineCode>{` block.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`try {
  // ...

  if (status === "succeeded") {
    setCheckoutSuccess(true);
    destroyCookie(null, "paymentIntentId");
  }
} catch (err) {
  alert(err.message);
  setCheckoutError(err.message);
}
`}</code></pre>
    <p>{`Then before we render the form inside `}<inlineCode parentName="p">{`return`}</inlineCode>{`, return a successful paragraph if `}<inlineCode parentName="p">{`checkoutSuccess`}</inlineCode>{` is truthy.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`if (checkoutSuccess) return <p>Payment successful!</p>;
`}</code></pre>
    <p>{`Finally, somewhere inside the `}<inlineCode parentName="p">{`<form>`}</inlineCode>{` add the following;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  checkoutError && <span style={{ color: "red" }}>{checkoutError}</span>;
}
`}</code></pre>
    <hr></hr>
    <p>{`You did it!`}</p>
    <p><img parentName="p" {...{
        "src": "https://dev-to-uploads.s3.amazonaws.com/i/e9eakcjv7iyat4flgcrv.gif",
        "alt": "Successful payment"
      }}></img></p>
    <p>{`If you check the Stripe Dashboard you will also see the successful Payment Intent!`}</p>
    <p><img parentName="p" {...{
        "src": "https://dev-to-uploads.s3.amazonaws.com/i/uv9vqwudz4fpvx0qs428.png",
        "alt": "Stripe payment success"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://dev-to-uploads.s3.amazonaws.com/i/yvju80uq28o0hzng2aq5.png",
        "alt": "Stripe payment success logs"
      }}></img></p>
    <hr></hr>
    <h2>{`Final `}<inlineCode parentName="h2">{`pages/checkout.js`}</inlineCode></h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from "react";
import Stripe from "stripe";
import { parseCookies, setCookie } from "nookies";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "../components/CheckoutForm";

const stripePromise = loadStripe("STRIPE_PUBLISHABLE_KEY");

export const getServerSideProps = async (ctx) => {
  const stripe = new Stripe("STRIPE_SECRET_KEY");

  let paymentIntent;

  const { paymentIntentId } = await parseCookies(ctx);

  if (paymentIntentId) {
    paymentIntent = await stripe.paymentIntents.retrieve(paymentIntentId);

    return {
      props: {
        paymentIntent,
      },
    };
  }

  paymentIntent = await stripe.paymentIntents.create({
    amount: 1000,
    currency: "gbp",
  });

  setCookie(ctx, "paymentIntentId", paymentIntent.id);

  return {
    props: {
      paymentIntent,
    },
  };
};

const CheckoutPage = ({ paymentIntent }) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm paymentIntent={paymentIntent} />
  </Elements>
);

export default CheckoutPage;
`}</code></pre>
    <h2>{`Final `}<inlineCode parentName="h2">{`CheckoutForm.js`}</inlineCode></h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { destroyCookie } from "nookies";

const CheckoutForm = ({ paymentIntent }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [checkoutError, setCheckoutError] = useState();
  const [checkoutSuccess, setCheckoutSuccess] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const {
        error,
        paymentIntent: { status },
      } = await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (error) throw new Error(error.message);

      if (status === "succeeded") {
        setCheckoutSuccess(true);
        destroyCookie(null, "paymentIntentId");
      }
    } catch (err) {
      alert(err.message);
      setCheckoutError(err.message);
    }
  };

  if (checkoutSuccess) return <p>Payment successful!</p>;

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />

      <button type="submit" disabled={!stripe}>
        Pay now
      </button>

      {checkoutError && <span style={{ color: "red" }}>{checkoutError}</span>}
    </form>
  );
};

export default CheckoutForm;
`}</code></pre>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/notrab/nextjs-stripe-intents"
        }}>{`Get the code`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      